import * as React from "react"
import cx from "classnames"

function SvgSoundcloudIcon({ title, titleId, ...props }) {

  const colors = Array.from(props.fill.split('.'))
  // console.log('light: ', colors[0])
  // console.log('dark: ', colors[1])

  return (
    <svg
      width={48}
      height={48}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby={titleId}
      className={cx(`${colors[0] === 'green' ? `fill-green hover:fill-blueGray` : `fill-${colors[0]} hover:fill-gray`} dark:fill-${colors[1]} dark:hover:fill-white scale-100 xl:scale-125 hover:scale-125 xl:hover:scale-150 rotate-0 hover:rotate-6 transition duration-300`)}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M1.594 32.605l.797-3.375-.797-3.469c0-.062-.047-.11-.14-.14a.886.886 0 00-.282-.047 1.04 1.04 0 00-.328.046c-.094.032-.14.079-.14.141L0 29.23l.703 3.375c0 .031.047.062.14.094.095.03.204.047.329.047a.886.886 0 00.281-.047c.094-.032.14-.063.14-.094zm4.922 1.828l.703-5.156-.703-8.063c0-.093-.055-.18-.164-.258a.668.668 0 00-.399-.117c-.156 0-.289.04-.398.117-.11.079-.164.165-.164.258l-.61 8.063.61 5.156c0 .125.054.219.164.281.11.063.242.094.398.094.156 0 .29-.031.399-.094a.304.304 0 00.164-.281zm4.921-.047l.563-5.11-.563-10.687a.387.387 0 00-.187-.351.87.87 0 00-.469-.117.87.87 0 00-.469.117.513.513 0 00-.234.351L9.61 29.277l.47 5.11c.03.124.108.226.233.304a.87.87 0 00.47.117.87.87 0 00.468-.117c.125-.078.188-.18.188-.305zm4.922-.094l.422-5.015-.422-10.782a.574.574 0 00-.257-.375.954.954 0 00-.54-.14.984.984 0 00-.515.14c-.156.094-.235.22-.235.375l-.421 10.782.421 5.015c0 .157.079.282.235.375.156.094.328.141.515.141a.953.953 0 00.54-.14.573.573 0 00.257-.376zm4.875-.047l.375-4.968-.375-12.375c0-.157-.086-.297-.257-.422a.971.971 0 00-.586-.188c-.25 0-.453.063-.61.188-.156.125-.234.265-.234.422l-.328 12.375.328 4.968c0 .157.078.297.234.422.157.125.36.188.61.188a.971.971 0 00.586-.188c.171-.125.257-.265.257-.422zm3.422.61h17.156c1.72 0 3.18-.586 4.383-1.758C47.398 31.925 48 30.511 48 28.855c0-1.656-.602-3.063-1.805-4.219-1.203-1.156-2.664-1.734-4.383-1.734-.437 0-.851.039-1.242.117-.39.078-.773.195-1.148.352a9.998 9.998 0 00-1.102-3.797 10.959 10.959 0 00-2.367-3.047 10.956 10.956 0 00-3.351-2.063c-1.266-.5-2.618-.75-4.055-.75-.688 0-1.367.063-2.04.188a9.223 9.223 0 00-1.898.562c-.25.063-.414.149-.492.258-.078.11-.117.258-.117.445v18.985c0 .187.063.343.188.468a.84.84 0 00.468.235z"
        fill=""
      />
    </svg>
  )
}

export default SvgSoundcloudIcon
