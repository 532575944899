import * as React from "react"
import cx from "classnames"

function SvgGithubIcon({ title, titleId, ...props }) {

  const colors = Array.from(props.fill.split('.'))
  // console.log(colors)

  return (
    <svg
      width={48}
      height={48}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby={titleId}
      className={cx(`${colors[0] === 'green' ? `fill-green hover:fill-blueGray` : `fill-${colors[0]} hover:fill-gray`}  dark:fill-${colors[1]} dark:hover:fill-white scale-100 xl:scale-125 hover:scale-125 xl:hover:scale-150 rotate-0 hover:rotate-6 transition duration-300`)}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M16.055 37.75c0 .195-.223.35-.503.35-.32.029-.542-.126-.542-.35 0-.193.222-.348.503-.348.29-.03.542.126.542.349zm-3.01-.436c-.068.194.126.417.416.475.252.097.542 0 .6-.193.058-.194-.126-.417-.416-.504-.252-.068-.532.029-.6.222zm4.278-.164c-.281.068-.475.252-.446.475.03.194.281.32.571.252.281-.068.475-.252.445-.446-.029-.184-.29-.31-.57-.281zM23.69 0C10.268 0 0 10.208 0 23.655c0 10.751 6.755 19.951 16.403 23.19 1.239.222 1.674-.544 1.674-1.174 0-.6-.029-3.916-.029-5.952 0 0-6.774 1.454-8.196-2.89 0 0-1.104-2.82-2.69-3.547 0 0-2.217-1.523.154-1.493 0 0 2.41.193 3.736 2.5 2.119 3.743 5.67 2.667 7.055 2.027.222-1.551.851-2.627 1.548-3.267-5.41-.601-10.868-1.386-10.868-10.713 0-2.666.736-4.003 2.284-5.71-.252-.63-1.074-3.228.252-6.582C13.345 9.414 18 12.66 18 12.66a22.699 22.699 0 016.077-.824c2.062 0 4.142.281 6.078.824 0 0 4.655-3.257 6.677-2.617 1.326 3.364.504 5.952.252 6.582 1.548 1.716 2.497 3.054 2.497 5.71 0 9.356-5.7 10.102-11.11 10.713.89.766 1.645 2.22 1.645 4.498 0 3.267-.029 7.31-.029 8.105 0 .63.445 1.396 1.674 1.173C41.44 43.606 48 34.406 48 23.655 48 10.208 37.113 0 23.69 0zM9.406 33.437c-.125.097-.096.32.068.504.155.155.378.223.503.097.126-.097.097-.32-.067-.504-.155-.156-.378-.223-.504-.097zm-1.045-.786c-.067.126.03.281.223.378.155.097.348.068.416-.067.068-.127-.029-.282-.223-.379-.193-.058-.348-.029-.416.068zm3.136 3.452c-.155.126-.097.416.126.6.222.224.503.253.629.098.125-.126.067-.417-.126-.601-.213-.223-.503-.253-.63-.097zm-1.104-1.425c-.154.097-.154.349 0 .572.155.222.417.32.542.222.155-.126.155-.378 0-.6-.135-.224-.387-.32-.542-.194z"
        fill=""
      />
    </svg>
  )
}

export default SvgGithubIcon
